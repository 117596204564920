import { amgApi } from "@/service/axios";

class NotificationAppService {
  async insertNotification(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/insert-app-default-notifications",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error insertNotification");
    }
  }

  async getAppDefaultNotifications() {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/get-app-default-notifications"
      );
      return data.data;
    } catch (error) {
      console.log("Error getAppDefaultNotifications");
    }
  }

  async getClientsApp(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/app-get-clients-send-notification",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error getClientsApp");
    }
  }

  async sendNotification(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/send-notification",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error sendNotification");
    }
  }
}

export default new NotificationAppService();
